<template>
  <div>
    <el-form :disabled="!isSelection" class="mar-v-1">
      <el-tooltip class="tooltip-item" effect="dark" :content="$tf('delete_video_tooltip')" placement="top" :disabled="isTooltipDisabled" :open-delay="200">
        <span>
          <confirm-button :target="target" :header="$tf('delete_video_confirm')" type="default" @confirm="deleteHandler" :disabled="isDeleteDisabled">
          </confirm-button>
        </span>
      </el-tooltip>

      <el-dropdown @command="handleProcess" placement="top" :hide-timeout="300">
        <el-button size="small">
          <span>{{ $tf('process') }}</span> <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown" size="small">
          <el-dropdown-item command="default">
            <confirm-menu-item :header="$tf('confirm_video_processing')" :title="$tf('default')" :hidden="areAllVideosDisabled" @confirm="processHandler">
            </confirm-menu-item>
          </el-dropdown-item>
          <el-dropdown-item command="custom">{{ $tf('custom_parameters') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-button size="small" @click="stopHandler">
        <template v-if="isSelection">
          <el-popover placement="top-start" trigger="hover">
            <div>{{ $tf('stop_video_processing_warning') }}</div>
            <span slot="reference">{{ $tf('stop') }}</span>
          </el-popover>
        </template>
        <span v-else>{{ $tf('stop') }}</span>
      </el-button>
    </el-form>

    <el-table
      name="videos-table"
      stripe
      ref="table"
      class="videos-table"
      :data="mergedItems"
      v-loading="state.loading"
      :size="$vars.sizes.table || $vars.sizes.common"
      @row-click="rowClick"
      @selection-change="selectionChangeHandler"
      @sort-change="handleSort"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="id" :label="$tf('id')" sortable="custom" width="60"></el-table-column>
      <el-table-column prop="name" :label="$tf('name')">
        <template slot-scope="{ row }">
          <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ row.name }}</router-link>
          <div>{{ row.comment }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="events" :label="$tf(countItem.i18n)" width="120" align="center" v-for="countItem in objectsCountItems">
        <template slot-scope="{ row }">
          <div class="text-center">{{ row[countItem.fieldName] }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="status" :label="$tf('status')" width="160" align="center">
        <template slot-scope="{ row }">
          <div class="text-center" :style="getRowStatusStyle(row)">
            <span>{{ getRowStatus(row) }}</span>
            <span v-if="getStatusSupportProgress(row)">{{ getRowProgressString(row) }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="processing_date" :label="$tf('processed,,2')" width="140" align="center">
        <template slot-scope="{ row }">
          <div class="text-center" v-if="row.processing_start_date">
            {{ $filters.formatDate(row.processing_start_date) }}
            <span class="text-tetriary">{{ $filters.formatTimeHHMM(row.processing_start_date) }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="created_date" sortable="custom" :label="$tf('created')" width="140" align="center">
        <template slot-scope="{ row }">
          <div class="text-center">
            <span>{{ $filters.formatDate(row.created_date) }}</span>
            <span class="text-tetriary">{{ $filters.formatTimeHHMM(row.created_date) }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import _ from '@/apps/common/lodash';
import axios from 'axios';
import qs from 'qs';
import ConfirmButton from '../confirm/confirm-button';
import ConfirmMenuItem from '../confirm/confirm-menu-item';
import { VideoProgressStatuses, VideoStatuses, VideoStatusToColor } from './video.constants';
import { ObjectsTypePluralForm, ObjectsTypeSingleForm } from '@/store/objects/get.module';

export default {
  components: {
    ConfirmButton,
    ConfirmMenuItem
  },
  name: 'table-videos',
  props: {
    filter: {
      type: Object,
      default() {
        return this.$store.state.videos.filter.current;
      }
    },
    disableFetchOnMount: {
      type: Boolean,
      required: false,
      default: false
    },
    cases: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      selection: [],
      loadingItems: []
    };
  },
  computed: {
    objectsCountItems() {
      return this.$store.getters.enabledObjects.map((v) => {
        const fieldName = `${ObjectsTypeSingleForm[v]}_count`,
          i18n = v;
        return { i18n, fieldName };
      });
    },
    state() {
      return this.$store.state.videos;
    },
    mergedItems() {
      const stateItems = this.filteredCases(this.state.items);
      const loadingItems = this.filteredCases(this.loadingItems);
      return this.loadingItems ? [].concat(stateItems, loadingItems) : stateItems;
    },
    areAllVideosDisabled() {
      return this.selection.every((item) => this.getRowStatus(item) === VideoStatuses.Disabled);
    },
    isSelection() {
      return this.selection && this.selection.length;
    },
    isDeleteDisabled() {
      if (!this.isSelection) return true;

      const inProgress = VideoStatuses.Inprogress,
        awaiting = VideoStatuses.Awaiting,
        uploading = VideoStatuses.Uploading;
      return this.selection.some((item) => {
        const status = this.getRowStatus(item);
        return status === inProgress || status === awaiting || status === uploading;
      });
    },
    isTooltipDisabled() {
      return !(this.isDeleteDisabled && this.isSelection);
    },
    target() {
      return this.selection.length > 1 ? 'videos' : 'video';
    },
    enabledObjects() {
      return this.$store.getters.enabledObjects;
    }
  },
  watch: {
    'filter.ordering': function () {
      this.applySort();
      this.fetchVideos();
    }
  },
  beforeCreate() {
    this.$store.state.videos.items = [];
  },
  mounted() {
    if (!this.disableFetchOnMount) {
      this.fetchVideos();
    }
    this.$nextTick(this.applySort.bind(this));
    this.$store.state.app.fileHandler = (files) => this.selectFiles(files);
  },
  methods: {
    getRowStatus(item) {
      let { status } = item.health_status;
      if (status === VideoStatuses.Disabled && item.finished) {
        status = VideoStatuses.Completed;
      }
      return status;
    },

    getStatusSupportProgress(item) {
      return VideoProgressStatuses.includes(item.health_status.status);
    },

    getRowProgressString(item) {
      return ' ' + this.$filters.floatToPercent(item.progress || 0);
    },

    getRowStatusStyle(item) {
      const status = this.getRowStatus(item),
        color = VideoStatusToColor[status.toLowerCase()];
      return { color };
    },

    rowClick(item, column) {
      if (column.type === 'selection') return;

      if (this.$store.state.app.key.ctrl) {
        this.$refs.table.toggleRowSelection(item);
      } else {
        this.$router.push({ path: this.getItemLink(item) });
      }
    },

    selectionChangeHandler(v) {
      this.selection = v;
    },

    getItemLink(item) {
      return '/videos/' + encodeURIComponent(item.id) + '/';
    },

    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch(() => {
        item.active = !v;
      });
      return false;
    },

    async deleteHandler() {
      const items = this.clearTableItemsSelection();
      await this.executeItemsActions(items, this.state.Action.Delete);
      await this.fetchVideos();
    },

    async processHandler() {
      const items = this.clearTableItemsSelection();
      await this.executeItemsActions(items, this.state.Action.Process);
    },

    async stopHandler() {
      const items = this.clearTableItemsSelection();
      await this.executeItemsActions(items, this.state.Action.Stop);
    },

    async executeItemsActions(items, action) {
      for (const [item, result] of this.dispatchItemsActionsSequence(items, action)) {
        try {
          await result;
          this.successNotify(this.$tf('action | success') + ` (id:${item.id})`);
        } catch (e) {
          this.errorNotify(e);
        }
      }
    },

    *dispatchItemsActionsSequence(items, action) {
      for (const item of items) {
        yield [item, this.$store.dispatch(action, item)];
      }
    },

    async fetchVideos() {
      const response = await this.$store.dispatch(this.state.Action.Get, { filter: this.filter });
      this.state.items = response.results;
    },

    clearTableItemsSelection() {
      const selection = [...this.selection];
      this.$refs.table.clearSelection();
      return selection;
    },

    handleProcess(type) {
      switch (type) {
        case 'default':
          if (this.areAllVideosDisabled) this.processHandler();
          break;

        case 'custom':
          const queryArgsString = qs.stringify({ ids: this.selection.map((v) => v.id) });
          this.$router.push(`/videos/multi-edit/${queryArgsString}`);
          break;
      }
    },

    applySort() {
      const ordering = this.filter.ordering || 'id',
        order = ordering[0] === '-' ? 'descending' : 'ascending',
        prop = ordering.match(/^-?(.*)/)[1];

      this.$refs.table && this.$refs.table.sort(prop, order);
    },

    handleSort(v) {
      if (!v) return;
      const ordering = { ascending: v.prop, descending: `-${v.prop}` };
      this.filter.ordering = ordering[v.order] || null;
    },

    selectFiles(items) {
      items.map(this.createItem);
    },

    async saveItem(item) {
      return await this.$store.dispatch(this.state.Action.Create, item);
    },

    moveFromLoadingToStateItems(item) {
      this.loadingItems = this.loadingItems.filter((v) => v !== item);
      if (!this.state.items.find((v) => v.id === item.id)) this.state.items.push(item);
    },

    async createItem(file) {
      const item = { ..._.cloneDeep(this.state.item.empty), name: file.name, save_to: this.filter.save_to },
        cleanDetectors = _.pickBy(item.stream_settings.detectors, (v, k) => this.enabledObjects.includes(ObjectsTypePluralForm[k])),
        changedItem = { ...item, stream_settings: { ...item.stream_settings, detectors: cleanDetectors } },
        cleanItem = _.pickBy(changedItem, (v, k) => v && this.state.item.fields.includes(k));
      let uploadResult, saveResult;

      this.loadingItems.push(item);

      try {
        saveResult = await this.saveItem(cleanItem);
        Object.assign(item, saveResult);
        this.successNotify();
        if (item.id) {
          this.moveFromLoadingToStateItems(item);
          await this.uploadItem(file, item);
          this.successNotify();
        }
      } catch (e) {
        this.errorNotify(e);
      } finally {
        await this.fetchVideos();
      }
      return [uploadResult, saveResult];
    },

    async uploadItem(file, item) {
      item.health_status.status = 'UPLOADING';
      const headers = {
          Authorization: 'Token ' + encodeURIComponent(this.$store.state.app.token)
        },
        request = {
          url: this.$store.state.config.server.url + 'videos/' + item.id + '/upload/source_file',
          method: 'PUT',
          headers,
          data: file,
          timeout: 1e6,
          onUploadProgress: (e) => {
            const progress = e.loaded / e.total,
              currentItem = this.state.items.find((v) => v.id === item.id);
            if (currentItem) {
              currentItem.health_status.status = 'UPLOADING';
              this.$set(currentItem, 'progress', progress);
            }
          }
        };
      return axios(request);
    },

    successNotify(message) {
      this.$notify({ type: 'success', message: message || this.$tf('action | success') });
    },

    errorNotify(e) {
      this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
    },

    filteredCases(itemArr) {
      return this.cases ? itemArr : itemArr.filter((item) => !item.save_to?.startsWith('cases:'));
    }
  }
};
</script>
